<template>
  <div>
    <ReportCenterTitle v-if="!inside" title="gradeBook" :count="count" />
    <h6 class="mt-2" v-else>
      <router-link to="/gradeBook"
        >{{ $t("gradeBook") }} ({{ count }})</router-link
      >
    </h6>
    <export-excell
      :checkedList="checkedList"
      :selectedAllItemWithoutPage="selectedAllItemWithoutPage"
      :name="`${checkedList.length} ${$t('users')}: ${$t('gradeBook')}`"
      :fields="fields"
      :count="count"
      @getAll="GET_ALL"
    ></export-excell>
    <Table
      checkable
      :check-value="checkedList"
      @change:selectAllWithoutPage="SET_SELECT_ALL_WITHOUT_PAGE"
      @change:checkedList="(e) => (checkedList = e)"
      :hideHeader="inside"
      :count="count"
      :headers="headers"
      no-search
      :loading="isLoading"
      :items="items"
      no-card
    >
      <template #user="{ item }">
        <router-link :to="`/user/${item.userInfo.id}`">
          <span class="text-primary">{{ item.userInfo.fullname }}</span>
          <div>{{ item.userInfo.email }} - {{ item.userInfo.phone }}</div>
        </router-link>
      </template>
      <template #totalCourseCount="{ item }">
        <h5>
          <img src="/images/video.png" class="mx-2" width="20" alt="" />
          {{ item.totalCourseCount }}
        </h5>
      </template>
      <template #totalVideoCount="{ item }">
        <h6>
          <img src="/images/video.png" class="mx-2" width="20" alt="" />
          {{ item.totalWatchedVideoCount }} / {{ item.totalVideoCount }}
        </h6>
        <div class="mt-1">
          <span
            :class="
              courseRateClass(
                (item.totalWatchedVideoCount * 100) / item.totalVideoCount
              )
            "
          >
            %
            {{
              item.totalVideoCount === 0
                ? "0.00"
                : (
                    (item.totalWatchedVideoCount * 100) /
                    item.totalVideoCount
                  ).toFixed(2) || 0
            }}
            {{ $t("rateComplete") }}
          </span>
        </div>
      </template>
      <template #courseRate="{ item }">
        <h6 :class="courseRateClass(item.courseRate)">
          {{ item.courseRate }} p.
        </h6>
      </template>
      <template #totalStudyTime="{ item }">
        <h6>
          {{ toHHMMSS(item.totalStudyTime) }}
        </h6>
      </template>
    </Table>
    <div v-if="!inside" class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
  </div>
</template>

<script>
import ReportCenterTitle from "../Title.vue";
import Table from "../../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import { Currency } from "gurhan/helpers/Currency";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import ExportExcell from "../ExportExcell.vue";
export default {
  components: {
    ExportExcell,
    BoostrapPagination,
    ReportCenterTitle,
    Table,
  },
  props: {
    inside: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 10,
    },
  },
  data() {
    return {
      checkedList: [],
      selectedAllItemWithoutPage: false,
      fields: {},
      items: [],
      isLoading: true,
      count: 0,
      page: 1,
      headers: [
        {
          title: this.$t("user"),
          value: "user",
          col: 12,
          md: 6,
          lg: 6,
          xl: 6,
        },
        {
          title: this.$t("totalCourseCount"),
          value: "totalCourseCount",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("totalVideoCount"),
          value: "totalVideoCount",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("totalStudyTime"),
          value: "totalStudyTime",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("courseRate"),
          value: "courseRate",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    formatter,
    Currency,
    SET_SELECT_ALL_WITHOUT_PAGE(all) {
      this.selectedAllItemWithoutPage = all;
    },
    CREATE_EXCEL_FIELD() {
      this.fields = {
        [`${this.$t("name")}`]: {
          field: "userInfo.fullname",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("email")}`]: {
          field: "userInfo.email",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("phone")}`]: {
          field: "userInfo.phone",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("totalCourseCount")}`]: {
          field: "totalCourseCount",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("totalVideoCount2")}`]: {
          field: "totalVideoCount",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("totalVideoCount")}`]: {
          field: "totalWatchedVideoCount",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("totalStudyTime")}`]: {
          field: "totalStudyTime",
          callback: (value) => {
            return this.toHHMMSS(value);
          },
        },
        [`${this.$t("courseRate")}`]: {
          field: "courseRate",
          callback: (value) => {
            return value;
          },
        },
      };
    },
    async GET_ALL(response, fromCache) {
      const responsed = await this.$api.get(
        `Statistic/GetUserCourseDetail?page=1&limit=${this.count}&status=0`
      );
      if (responsed.message === "OK") {
        this.checkedList = responsed.data.items.map((i) => ({
          ...i,
          email: i.userInfo.email,
        }));
        response(
          responsed.data.items.map((i) => ({
            ...i,
            email: i.userInfo.email,
          })),
          fromCache
        );
      }
    },
    toHHMMSS(secs) {
      var sec_num = parseInt(secs, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    },
    courseRateClass(rate) {
      if (rate > 85) return "text-success";
      else if (rate > 70) return "text-info";
      else if (rate > 50) return "text-warning";
      else return "text-danger";
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { page } });
      this.GET_ITEMS();
    },
    async GET_ITEMS() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Statistic/GetUserCourseDetail?page=${this.page}&limit=${this.limit}&status=0`
      );
      if (response.message === "OK") {
        this.items = response.data.items.map((i) => ({
          ...i,
          email: i.userInfo.email,
        }));
        this.count = response.data.count;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.CREATE_EXCEL_FIELD();
    const q = this.$route.query;
    if (!isNaN(Number(q.page))) this.page = Number(q.page);
    this.GET_ITEMS();
  },
};
</script>

<style></style>
